<template>
  <layout page="dashboard">
    <page-title icon="lni lni-home" :breadcrumb="breadcrumb">Dashboard</page-title>
    <section class="cards section">
      <div class="columns">
        <div class="column">
          <card-main title="Alunos" data="512" icon="lni lni-users lni-32" />
        </div>
        <div class="column">
          <card-main title="Novos Alunos" data="32" icon="lni lni-stats-up lni-32" />
        </div>
      </div>
    </section>
  </layout>
</template>

<script>
import { DASHBOARD } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import CardMain from '@/components/admin/CardMain'

export default {
  name: 'Dashboard',
  components: {
    Layout,
    PageTitle,
    CardMain
  },
  computed: {
    breadcrumb () {
      return [{ ...DASHBOARD, isActive: true }]
    }
  }
}
</script>

<style lang="scss" scoped>
.cards {
  .column {
    padding: 0;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1024px) {
  .cards {
    .column {
      margin-bottom: 0;
      padding: .75rem;
    }
  }
}
</style>
