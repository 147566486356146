<template>
  <div class="card is-card-widget">
    <div class="card-content">
      <div class="level is-mobile">
        <div class="level-item">
          <div class="is-widget-label">
            <p class="subtitle is-spaced">{{ title }}</p>
            <p class="title">{{ data }}</p>
          </div>
        </div>
        <div class="level-item has-widget-icon">
          <div class="is-widget-icon">
            <div class="icon has-text-primary is-large">
              <i :class="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardMain',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: String
    },
    icon: {
      type: String
    }
  }
}
</script>
